import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Input,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { inputStyleObj, selectStyleObj } from "./WriteOff";
import { usePolicySearch, useCurrencies } from "./hooks";
import { CommonButton } from "../common/commonButton";
import { CommonSelect } from "../common";
import instance from "../../redux/api";
import { bindingAgreementOptions } from "./constants";

const AddNewAllocation = ({ onSuccess, onCancel, transaction, isEditingRecord }) => {
  const [policyNumber, setPolicyNumber] = useState("");
  const [submitType, setSubmitType] = useState("");
  const [isAddingManually, setIsAddingManually] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [manualPolicyDetails, setManualPolicyDetails] = useState({
    Producing_Entity: "",
    Syndicate_Binder: "",
    Policy_Line_Ref: "",
    UMR_Number: "",
    Third_Party_Capacity: "No",
    SCM_Partner: "",
    SCM_Insurer_partner_name: "",
    Binding_Agreement: "",
    original_ccy: "",
    settlement_currency: "",
    installment_amount_org: "",
    allocation_eea: "",
    allocation_invoice_verification: "",
  });
  const [isApiProgressBar, setIsApiProgressBar] = useState(false);
  const [policyRecord, setpolicyRecord] = useState(false);
  const [addValidationError, setAddValidationError] = useState("");
  const { policyDetails, searchPolicy, addAllocation, addManualAllocation } =
    usePolicySearch(
      transaction,
      setSubmitType,
      setIsAddingManually,
      manualPolicyDetails,
      onSuccess,
      setIsApiProgressBar,
      isEditingRecord
    );

  const [policyOptions, setPolicyOptions] = useState([]);
  const [isPolicySearchInProgress, setIsPolicySearchInProgress] =
    useState(false);
  const [producingEntityData, setProducingEntityData] = useState([]);
  const [bindingAgreementData, setbindingAgreementData] = useState([]);
  const [SCMPartnerNameData, setSCMPartnerNameData] = useState([]);

  // Get Currencies
  const currencies = useCurrencies();

  const searchButton = (
    <CommonButton
      text="Search"
      handleClick={() => {
        searchPolicy(policyNumber);
        setpolicyRecord(true);
      }}
      sx={{ borderRadius: 100 }}
      disabled={
        validationError || policyNumber.length < 12 || policyOptions.lenth === 0
      }
      hoverColor="#FF5A01"
    />
  );

  const manualEnterButton = (
    <CommonButton
      text="Enter Manually"
      handleClick={() => {
        setIsAddingManually(true);
        setSubmitType("add");
      }}
      sx={{ borderRadius: 100 }}
      hoverColor="#FF5A01"
      disabled={policyOptions.length > 0}
    />
  );

  const addButton = (
    <CommonButton
      text="Add Allocation"
      type="submit"
      sx={{ borderRadius: 100 }}
      hoverColor="#FF5A01"
      disabled={isApiProgressBar}
    />
  );

  const submitButton = {
    search: searchButton,
    manual: manualEnterButton,
    add: addButton,
    [""]: null,
  }[submitType];

  const handlePolicyDetailsChange = useCallback(
    (e) => {

      const { name, value } = e.target;
      setManualPolicyDetails((d) => ({
        ...d,
        [name]: value,
      }));

      if (name === "Binding_Agreement") {
        if (value === "NON-SCM") {
          setManualPolicyDetails((d) => ({
            ...d,
            ["SCM_Partner"]: "",
          }));
        }
        setManualPolicyDetails((d) => ({
          ...d,
          Third_Party_Capacity: value === "SCM" ? "Yes" : "No",
        }));
      }

      if (name === "Policy_Line_Ref") {
        const regex = /^(P|M|F)[A-Z0-9]{11}$/;
        if (!regex.test(value)) {
          setAddValidationError(
            'Policy number must start with "P" or "M" or "F" characters only and length should be 12 characters.'
          );
        } else {
          setAddValidationError("");
        }
      }
    },
    [setManualPolicyDetails]
  );

  const handleAddPolicy = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isAddingManually) {
      addAllocation();
    } else {
      if (addValidationError !== "") return;
      addManualAllocation();
    }
  });

  const handleInputChage = (inputValue) => {
    let value = inputValue.toUpperCase().replace(/[^A-Z0-9]/g, "");
    value = value.slice(0, 12);

    if (
      value.charAt(0) !== "P" &&
      value.charAt(0) !== "M" &&
      value.charAt(0) !== "F"
    ) {
      value = value.slice(0, 1);
    }

    if (value === "") {
      setValidationError("");
    } else {
      const regex = /^(P|M|F)[A-Z0-9]{11}$/;
      if (!regex.test(value)) {
        setValidationError(
          'Policy number must start with "P" or "M" or "F" characters only and length should be 12 characters.'
        );
      } else {
        setValidationError("");
        setManualPolicyDetails({
          ...manualPolicyDetails,
          Policy_Line_Ref: value,
        });
      }
    }
    setPolicyNumber(value);

    getPolicyList(value);

    // setManualPolicyDetails((d) => ({
    //   ...d,
    // Policy_Line_Ref: value
    //   //e.target.value,
    // }));
    // setSubmitType("search");
  };

  useEffect(() => {
    if (policyNumber.length === 12 && policyOptions.length === 0) {
      setSubmitType("manual");
    } else if (policyNumber.length == 12 && policyOptions.length > 0) {
      setSubmitType("search");
    } else {
      setSubmitType("");
    }
  }, [policyOptions, policyNumber]);

  const getPolicyList = async (inputText) => {
    if (inputText?.length > 2) {
      try {
        setIsPolicySearchInProgress(true);
        const response = await instance.get(
          `documents/policy_line_ref/?policy_line_ref=${inputText}`
        );

        const allPolicies = response.data["all_policies"];
        // Check if the allPolicies array is empty
        setPolicyOptions(allPolicies);
        setIsPolicySearchInProgress(false);
      } catch (error) {
        setIsPolicySearchInProgress(false);
        console.error("Error fetching policies:", error);
      }
    }
  };

  const handlePolicyNumber = (name, value, reason) => {
    if (reason === "clear") {
      setPolicyNumber("");
    } else {
      setPolicyNumber(value);

      // validate drodown select
      const regex = /^(P|M|F)[A-Z0-9]{11}$/;
      if (!regex.test(value)) {
        setValidationError(
          'Policy number must start with "P" or "M" or "F" characters only and length should be 12 characters.'
        );
      } else {
        setValidationError("");
        setManualPolicyDetails({
          ...manualPolicyDetails,
          Policy_Line_Ref: value,
        });
      }
    }
  };

  const handleEntityNameDropdownChange = (name, value, reason, event) => {
    if (reason === "clear") {
      setManualPolicyDetails({
        ...manualPolicyDetails,
        [name]: "",
      });
    }
    setManualPolicyDetails({
      ...manualPolicyDetails,
      [name]: value ?? "",
    });
  };

  const handleSCMPartnerNameDropdownChange = (name, value, reason, event) => {
    if (reason === "clear") {
      setManualPolicyDetails({
        ...manualPolicyDetails,
        [name]: ""
      });
    }
    setManualPolicyDetails({
      ...manualPolicyDetails,
      [name]: value
    });
  };

  useEffect(() => {
    const fetchProducingEntityData = async () => {
      try {
        const response = await instance.get('documents/entity/');
        setProducingEntityData(response.data);
      } catch (error) {
        console.error('API call failed:', error);
      }
    };
    fetchProducingEntityData();
  }, []);

  useEffect(() => {
    const fetchBindingAgreementData = async () => {
      try {
        const response = await instance.get('documents/binding_agreement/');
        setbindingAgreementData(response.data);
      } catch (error) {
        console.error('API call failed:', error);
      }
    };
    fetchBindingAgreementData();
  }, []);

  useEffect(() => {
    const fetchSCMPartnerNameData = async () => {
      try {
        const response = await instance.get('documents/scm_partners/');
        setSCMPartnerNameData(response.data);
      } catch (error) {
        console.error('API call failed:', error);
      }
    };
    fetchSCMPartnerNameData();
  }, []);

  // Add a useEffect to log producingEntityData when it updates
  useEffect(() => {
    console.log('Cash Transfer Data:', producingEntityData);
  }, [producingEntityData]);

  useEffect(() => {
    console.log("Updated Producing Entity: ", manualPolicyDetails);
  }, [manualPolicyDetails]); // This effect runs after manualPolicyDetails has been updated

  // Add useEffect to update EEA/NON EEA based on Producing_Entity
  useEffect(() => {
    const isDisabledEEA = ['MEU2', 'MEEA'].includes(manualPolicyDetails.Producing_Entity);
    const newEEAValue = isDisabledEEA ?
    "EEA"
    : ["None", "Null", "NA", ""].includes(manualPolicyDetails?.Producing_Entity)
    ? ""
    : "NON EEA"

    setManualPolicyDetails(prevDetails => ({
      ...prevDetails,
      allocation_eea: newEEAValue
    }));

  }, [manualPolicyDetails.Producing_Entity]);

useEffect(() => {
  // Ensure policyDetails is not null before accessing its properties
  if (policyDetails) {
    const newEEAValue = policyDetails.Producing_Entity === "MEEA" || policyDetails.Producing_Entity === "MEU2"
      ? "EEA"
      : policyDetails.Producing_Entity ? "NON EEA" : "";

    setManualPolicyDetails(prevDetails => ({
      ...prevDetails,
      allocation_eea: newEEAValue
    }));
  }
}, [policyDetails]);

  return (
    <Dialog open onClose={onCancel} fullWidth>
      <form onSubmit={handleAddPolicy}>
        <DialogTitle style={{ background: "#FCFCFC" }}>
          {isEditingRecord ? "Edit Record" : "Add New Record"}
        </DialogTitle>
        <DialogContent>
          {submitType === "add" && isAddingManually ? (
            <Grid container>
              <Grid item xs={6} md={6} paddingRight={1}>
                <p style={{ fontSize: 13, color: "#17191B" }}>Policy Number</p>

                <Input
                  style={{ ...inputStyleObj }}
                  name="Policy_Line_Ref"
                  onChange={handlePolicyDetailsChange}
                  value={manualPolicyDetails.Policy_Line_Ref}
                  placeholder="Write details here"
                  required
                  disabled={true}
                />
                {addValidationError && (
                  <Typography style={{ color: "red", fontSize: 13 }}>
                    {addValidationError}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6} md={6} paddingLeft={1}>
                <p style={{ fontSize: 13, color: "#17191B" }}>Master Binder</p>

                <Input
                  style={{ ...inputStyleObj }}
                  name="Syndicate_Binder"
                  onChange={handlePolicyDetailsChange}
                  value={manualPolicyDetails.Syndicate_Binder}
                  placeholder="Write details here"
                  required
                />
              </Grid>
              <Grid item xs={6} md={6} paddingRight={1}>
                <p style={{ fontSize: 13, color: "#17191B" }}>UMR</p>

                <Input
                  style={{ ...inputStyleObj }}
                  name="UMR_Number"
                  onChange={handlePolicyDetailsChange}
                  value={manualPolicyDetails.UMR_Number}
                  placeholder="Write details here"
                  required
                />
              </Grid>
              <Grid item xs={6} md={6} paddingLeft={1}>
                <p style={{ fontSize: 13, color: "#17191B" }}>
                  Binding Agreement
                </p>

                <Select
                  style={{ ...selectStyleObj }}
                  name="Binding_Agreement"
                  onChange={handlePolicyDetailsChange}
                  value={manualPolicyDetails.Binding_Agreement}
                  placeholder="Write details here"
                  required
                >
                  {bindingAgreementData?.map((option) => (
                    <MenuItem value={option.binding_aggrement_type}>{option.binding_aggrement_type}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={6} md={6} paddingRight={1}>
                <p style={{ fontSize: 13, color: "#17191B" }}>
                  SCM Partner Name
                </p>
                <CommonSelect
                  placeholder="Select SCM Partner Name"
                  name="SCM_Partner"
                  value={manualPolicyDetails?.SCM_Partner}
                  required={manualPolicyDetails.Binding_Agreement === "SCM"}
                  disabled={
                    manualPolicyDetails.Binding_Agreement !== "SCM"
                  }
                  handleChange={(event, value, reason) =>
                    handleSCMPartnerNameDropdownChange(
                      "SCM_Partner",
                      value,
                      reason,
                      event
                    )
                  }
                  options={
                    SCMPartnerNameData?.map((option) => option?.partner_name) ||
                    []
                  }
                />
              </Grid>
              <Grid item xs={6} md={6} paddingLeft={1}>
                <p style={{ fontSize: 13, color: "#17191B" }}>
                  Producing Entity
                </p>
                <CommonSelect
                  placeholder="Select Producing Entity"
                  name="Producing_Entity"
                  value={manualPolicyDetails?.Producing_Entity}
                  required
                  handleChange={(event, value, reason) =>
                    handleEntityNameDropdownChange(
                      "Producing_Entity",
                      value,
                      reason,
                      event
                    )
                  }
                  options={
                    producingEntityData?.map((option) => option?.entity_divisions) ||
                    []
                  }
                />
              </Grid>
              <Grid item md={6} paddingRight={1}>
                <p style={{ fontSize: 13, color: "#17191B" }}>EEA / NON EEA</p>

                <Select
                  style={{ ...selectStyleObj }}
                  name="allocation_eea"
                  onChange={handlePolicyDetailsChange}
                  value={
                    manualPolicyDetails?.Producing_Entity === "MEEA" || manualPolicyDetails?.Producing_Entity === "MEU2"
                      ? "EEA"
                      : ["None", "Null", "NA", ""].includes(manualPolicyDetails?.Producing_Entity)
                      ? ""
                      : "NON EEA"
                  }
                  placeholder=""
                  required
                  disabled={true}
                >
                  <MenuItem value="EEA">EEA</MenuItem>
                  <MenuItem value="NON EEA">NON EEA</MenuItem>
                </Select>
              </Grid>
              <Grid item md={6} paddingLeft={1}>
                <p style={{ fontSize: 13, color: "#17191B" }}>
                  Invoice Verification
                </p>

                <Select
                  style={{ ...selectStyleObj }}
                  name="allocation_invoice_verification"
                  onChange={handlePolicyDetailsChange}
                  value={manualPolicyDetails.allocation_invoice_verification}
                  required
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </Grid>

              <Grid item md={6} paddingRight={1}>
                <p style={{ fontSize: 13, color: "#17191B" }}>
                  Original Currency
                </p>

                <Select
                  style={{ ...selectStyleObj }}
                  name="original_ccy"
                  onChange={handlePolicyDetailsChange}
                  value={manualPolicyDetails.original_ccy}
                  required
                >
                  {currencies?.map((option, optionIndex) => (
                    <MenuItem
                      key={`original_ccy_${option.currency_code}_${optionIndex}`}
                      value={option.currency_code}
                    >
                      {option.currency_code}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item md={6} paddingLeft={1}>
                <p style={{ fontSize: 13, color: "#17191B" }}>
                  Settlement Currency
                </p>

                <Select
                  style={{ ...selectStyleObj }}
                  name="settlement_currency"
                  onChange={handlePolicyDetailsChange}
                  value={manualPolicyDetails.settlement_currency}
                  required
                >
                  {currencies?.map((option, optionIndex) => (
                    <MenuItem
                      key={`original_ccy_${option.currency_code}_${optionIndex}`}
                      value={option.currency_code}
                    >
                      {option.currency_code}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item md={6} paddingRight={1}>
                <p style={{ fontSize: 13, color: "#17191B" }}>
                  Original Amount
                </p>
                <Input
                  style={{ ...inputStyleObj }}
                  name="installment_amount_org"
                  pattern="\d+"
                  onChange={handlePolicyDetailsChange}
                  onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                  value={manualPolicyDetails.installment_amount_org}
                  placeholder=""
                  required
                  type="number"
                />
              </Grid>
            </Grid>
          ) : (
            <>
              {submitType === "add" && policyRecord && policyDetails && (
                <Grid container gap={2} marginTop={4}>
                  <Grid item md={5}>
                    Policy Number:
                    <div style={{ fontWeight: "bold" }}>
                      {policyDetails.Policy_Line_Ref}
                    </div>
                  </Grid>
                  <Grid item md={5}>
                    Master Binder:
                    <div style={{ fontWeight: "bold" }}>
                      {policyDetails.Syndicate_Binder}
                    </div>
                  </Grid>
                  <Grid item md={5}>
                    UMR:
                    <div style={{ fontWeight: "bold" }}>
                      {policyDetails.UMR_Number}
                    </div>
                  </Grid>
                  <Grid item md={5}>
                    SCM Partner:
                    <div style={{ fontWeight: "bold" }}>
                      {policyDetails?.SCM_Partner == "nan"
                        ? ""
                        : policyDetails?.SCM_Partner || ""}
                    </div>
                  </Grid>
                  <Grid item md={5}>
                    Binding Agreement:
                    <div style={{ fontWeight: "bold" }}>
                      {policyDetails?.Three_Party_Capacity_Deployed?.toLowerCase() ===
                        "yes" && "SCM"}
                      {policyDetails?.Three_Party_Capacity_Deployed?.toLowerCase() ===
                        "no" && "1609"}
                    </div>
                  </Grid>
                  <Grid item md={5}>
                    Producing Entity:
                    <div style={{ fontWeight: "bold" }}>
                      {policyDetails?.Producing_Entity}
                    </div>
                  </Grid>
                  <Grid item md={5}>
                    EEA / NON EEA
                    <Select
                      style={{ ...selectStyleObj }}
                      name="allocation_eea"
                      onChange={handlePolicyDetailsChange}
                      value={
                        policyDetails?.Producing_Entity === "MEEA" ||
                        policyDetails?.Producing_Entity === "MEU2"
                          ? "EEA"
                          : ["None", "Null", "NA", ""].includes(policyDetails?.Producing_Entity)
                          ? ""
                          : "NON EEA"
                      }
                      placeholder=""
                      required
                      disabled={true}
                    >
                      <MenuItem value="EEA">EEA</MenuItem>
                      <MenuItem value="NON EEA">NON EEA</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item md={5}>
                    Invoice Verification
                    <Select
                      style={{ ...selectStyleObj }}
                      name="allocation_invoice_verification"
                      onChange={handlePolicyDetailsChange}
                      value={
                        manualPolicyDetails.allocation_invoice_verification
                      }
                      required
                    >
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              )}
            </>
          )}

          <>
            {submitType !== "add" && (
              <Grid container>
                <Grid item xs={12} md={12}>
                  <p style={{ fontSize: 13, color: "#17191B" }}>
                    Policy Number
                  </p>
                  {validationError && (
                    <Typography style={{ color: "red", fontSize: 13 }}>
                      {validationError}
                    </Typography>
                  )}
                  <CommonSelect
                    handleChange={(event, value, reason) =>
                      handlePolicyNumber("Policy Number", value, reason, event)
                    }
                    handleInputChage={(value) => handleInputChage(value)}
                    name="Policy Number"
                    // Map through your list of dropdown values
                    options={policyOptions?.map((option) => option?.policy_id)}
                    placeholder="Select Policy Number"
                    value={policyNumber}
                    customStyles={{ width: "395px" }}
                  />
                </Grid>
                {isPolicySearchInProgress && (
                  <Box
                    style={{
                      top: "-35px",
                      left: "165px",
                      position: "relative",
                    }}
                  >
                    <CircularProgress
                      size={30}
                      style={{
                        color: "rgb(255, 90, 1)",
                      }}
                    />
                  </Box>
                )}
              </Grid>
            )}

            {isApiProgressBar && (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CircularProgress
                  size={35}
                  style={{ color: "rgb(255, 90, 1)" }}
                />
              </Box>
            )}

            {/* {submitType === "add" && policyDetails && ( */}

            {/* )} */}
          </>
        </DialogContent>
        <DialogActions style={{ justifyContent: "flex-end" }}>
          <CommonButton
            text="Cancel"
            handleClick={onCancel}
            sx={{
              borderRadius: 100,
              backgroundColor: "#ffff",
              color: "#000000",
              border: "1px solid #BCBDBF",
            }}
            variant="outlined"
          />
          {validationError == "" && policyNumber.length > 1
            ? submitButton
            : null}
        </DialogActions>
      </form>
    </Dialog>
  );
};

AddNewAllocation.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default AddNewAllocation;

import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import instance from "../../redux/api";
import { customDateFormat, parseDate, startOfDay } from "./utils";
import { format, formatDate } from "date-fns";
import {
  numberFormatter,
} from "../../utils/generalFunctions";
import { toast } from "react-toastify";
import { workflowBankTransaction } from "../../Services/api";

const getAgeDays = (paymentReceiveDate, remainingBalance) => {
  if (remainingBalance <= 0 || !paymentReceiveDate) return 0;

  const diff =
    startOfDay(new Date()).getTime() -
    startOfDay(new Date(paymentReceiveDate)).getTime();

  const daysDiff = Math.floor(diff / 86400000);

  return daysDiff;
};

export const acctMonthToLabel = (acctDate) => {
  // const date = new Date();
  // const year = acctMonth % 10000;
  // const month = Math.floor(acctMonth / 10000);

  // date.setFullYear(year);
  // date.setMonth(month);
  return formatDate(new Date(acctDate), "MMM-yyyy");
};

export const getAging = (daysDiff) => {
  if (isNaN(daysDiff)) return "Other";
  if (daysDiff <= 15) return "0 - 15";
  if (daysDiff <= 30) return "15 - 30";
  if (daysDiff <= 60) return "31 - 60";
  if (daysDiff <= 90) return "61 - 90";
  if (daysDiff <= 120) return "91 - 120";
  if (daysDiff <= 180) return "121 - 180";
  if (daysDiff <= 365) return "181 - 365";
  return "Over 365";
};

export const useSearchParams = (name) => {
  const search = new URLSearchParams(window.location.search);
  return search.get(name) || "";
};

export const useBankDetails = (transactionId) => {
  const [bankDetails, setBankDetails] = useState([]);
  const [transactionDetails, setTransactionDetails] = useState({});

  useEffect(() => {
    if (!transactionId) return;
    // Fetch bank details based on the transaction ID
    instance
      .get(`bankmanagement/get_bankdetails_by_txn_id?txn_id=${transactionId}`)
      .then((response) => {
        const data = response?.data;
        setTransactionDetails(data);
      })
      .catch((error) => console.error("Error fetching bank details:", error));
  }, [transactionId]);


  useEffect(() => {
    const data = transactionDetails;
    const details = [
      {
        label: "Bank Name",
        value: data?.bank_details?.bank_name || "",
      },
      {
        label: "Bank Account Number",
        value: data?.Receiving_Bank_Account || "",
      },
      {
        label: "Receiving Entity Name",
        value: data?.bank_details?.entity_name || "",
      },
      {
        label: "Transaction date",
        value: customDateFormat(data.Payment_Receive_Date) || "",
      },
      {
        label: "Bank Txn Id",
        value: data.Bank_Transaction_Id || "",
      },
      {
        label: "Broker Branch",
        value: data.Broker_Branch || "",
      },
      {
        label: "Payment reference",
        value: data.Payment_Reference,
      },
      {
        label: "Payment/Bank Ccy",
        value: data.Bank_Currency_Code,
      },
      // {
      //   label: "Exchange charges",
      //   value: data.Bank_Exchange_Charges || "",
      // },

      {
        label: "Bank charges",
        value: numberFormatter(data?.Bank_Charges) || "",
      },
      {
        label: "Amount",
        value: numberFormatter(data.Receivable_Amount || ""),
      },
    ];
    setBankDetails(details);
  }, [transactionDetails]);

  return { bankDetails, transactionDetails };
};

export const useCorrectionTypes = () => {
  const [details, setDetails] = useState([]);

  useEffect(() => {
    instance
      .get(`documents/correction_type/`)
      .then((response) => {
        setDetails(response.data);
      })
      .catch((error) => console.error("Error fetching details:", error));
  }, []);

  return details;
};

export const useIssueCategory = () => {
  const [details, setDetails] = useState([]);

  useEffect(() => {
    instance
      .get(`documents/issue_catergory/`)
      .then((response) => {
        setDetails(response.data);
      })
      .catch((error) => console.error("Error fetching details:", error));
  }, []);

  return details;
};

const getAccMonthYear = (accMonth) => {
  let date = new Date(accMonth);

  if (isNaN(date.getTime())) date = new Date();

  return `${date.getMonth()}${date.getFullYear()}`;
};

export const usePolicySearch = (
  transaction,
  setSubmitType,
  setIsAddingManually,
  manualPolicyDetails,
  onSuccess,
  setIsApiProgressBar,
  isEditingRecord
) => {
  const [policyDetails, setPolicyDetails] = useState(null);
  const { userData } = useSelector((state) => state?.user);

  const searchPolicy = useCallback(
    (policyNumber) => {
      if (!policyNumber) return;
      // Fetch bank details based on the transaction ID
      setIsApiProgressBar(true);
      instance
        .get(
          `documents/get_policy_details_by_policy_ref/?Policy_Line_Ref=${policyNumber}`
        )
        .then(async (response) => {
          const data = response.data;
          if (!data.id) {
            return setSubmitType("manual");
          }
          const policyData = await instance.get(
            `documents/policydata/${data.id}`
          );
          setPolicyDetails(policyData.data);
          setSubmitType("add");
          setIsApiProgressBar(false);
        })
        .catch((error) => {
          console.error("Error fetching bank details:", error);
          setSubmitType("manual");
        });
    },
    [setSubmitType]
  );

  const addPolicy = useCallback(
    (policyDetails) => {
      if (!policyDetails) {
        alert("Please fill all the required filds");
        return;
      }
      if (Object.values(policyDetails).some((v) => !v && v !== 0)) {
        alert("Please fill all the filds");
        return;
      }

      setPolicyDetails(policyDetails);
      setSubmitType("add");
      setIsAddingManually(false);
    },
    [setPolicyDetails, setSubmitType, setIsAddingManually]
  );

  const createPolicy = useCallback(async () => {
    setIsApiProgressBar(true);
    const payload = {
      Producing_Entity: manualPolicyDetails?.Producing_Entity,
      Syndicate_Binder: manualPolicyDetails.Syndicate_Binder,
      Policy_Line_Ref: manualPolicyDetails?.Policy_Line_Ref,
      UMR_Number: manualPolicyDetails.UMR_Number,
      Three_Party_Capacity_Deployed: manualPolicyDetails.Binding_Agreement === "SCM" ? "Yes" : "No",
      SCM_Partner: manualPolicyDetails.SCM_Partner == ""
        ? null
        : manualPolicyDetails.SCM_Partner,
      SCM_Insurer_partner_name: manualPolicyDetails.SCM_Insurer_partner_name == ""
        ? null
        : manualPolicyDetails.SCM_Insurer_partner_name,
      Binding_Agreement: manualPolicyDetails.Binding_Agreement,
      allocation_invoice_verification: manualPolicyDetails?.allocation_invoice_verification,
      Third_Party_Capacity: manualPolicyDetails.Third_Party_Capacity,
      original_ccy: manualPolicyDetails.original_ccy,
      settlement_currency: manualPolicyDetails.settlement_currency,
      installment_amount_org: +manualPolicyDetails.installment_amount_org
    };
    return instance.post(`documents/policy_create/`, payload).then((response) => {
      setIsApiProgressBar(false)
      setIsAddingManually(true);
      setSubmitType("add");
      return response;
    }).catch(err => {
      console.log("error", err)
      setIsApiProgressBar(false)
      setSubmitType("manual")
    });
  }, [manualPolicyDetails]);

  const addAllocation = useCallback(async () => {
    setIsApiProgressBar(true);
    if (isEditingRecord && isEditingRecord.id) {
      try {
        const response = await instance.delete(`bankmanagement/cash_allocation/${isEditingRecord.id}/`);
        if (response.status === 204 || response.status === 200) {
          toast.success("Previous allocation removed successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          throw new Error(`Failed to delete previous allocation`);
        }
      } catch (error) {
        toast.error(`Error deleting previous allocation: ${error.message}`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsApiProgressBar(false);
        return; // Stop further execution if deletion fails
      }
    }
    const payload = {
      unallocated_amt: 0,
      allocated_amt: 0,
      receivable_amt: 0,
      XFIbatchno: 0,
      allocation_date: null,
      allocation_entity: policyDetails?.Producing_Entity || "-",
      allocation_status: "In Progress - CC",
      accounting_monthyear: transaction?.Accounting_Month ? format(
        new Date(transaction.Accounting_Month),
        "yyyy-MM-dd"
      ) : null,
      allocation_systemid: "-",
      bank_txn: transaction.id,
      cash_reference: "-",
      GXPbatchno: "-",
      allocatedby: "-",
      bank_curr: transaction?.bank_details?.currency|| "-",
      payment_currency: policyDetails?.Original_Ccy || transaction?.payment_currency,
      bank_roe: transaction?.bank_roe || 0,
      allocation_eea: manualPolicyDetails?.allocation_eea,
      allocation_invoice_verification:
        manualPolicyDetails?.allocation_invoice_verification,
      policy_pk: policyDetails?.id,
      policy_id: policyDetails?.Policy_Line_Ref,
      allocation_binder: policyDetails?.Syndicate_Binder,
      allocation_umr: policyDetails?.UMR_Number,
      binding_agreement: policyDetails?.Three_Party_Capacity_Deployed?.toLowerCase() === "yes" ?
        "SCM" :
        policyDetails?.Three_Party_Capacity_Deployed?.toLowerCase() === "no" ? "NON SCM" : null,
      allocation_scm: policyDetails?.SCM_Partner,
      allocation_scmname: policyDetails?.SCM_Insurer_partner_name,
      isActive: true,
      manual_entry: false,
      Third_Party_Capacity: policyDetails?.Three_Party_Capacity_Deployed ?? null,
      settlement_currency: policyDetails?.Settlement_Ccy ?? null,
      audit_fields:{
        user_id: userData?.id,
        fields: [
          {
            field_name: "policy_id",
            old_value: null,
            value: policyDetails?.Policy_Line_Ref
          },
          {
            field_name: "allocation_status",
            old_value: null,
            value: "In Progress - CC"
          },
          {
            field_name: "receivable_amt",
            old_value: null,
            value: null
          },
          {
            field_name: "allocated_amt",
            old_value: null,
            value: null
          }
        ],
        txn_id: null,
        audit_txn_name: "CASH_ALLOCATION"
      }
    };
    return instance
      .post(`bankmanagement/cash_allocation/`, payload)
      .then((response) => {
        setIsApiProgressBar(false);
        const data = response.data;
        setPolicyDetails(data);
        setSubmitType("add");
        onSuccess(data);
      })
      .catch((error) => {
        setIsApiProgressBar(false);
        toast.error("Failed to Add New Allocation", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        console.error("Error fetching bank details:", error);
      });
  }, [setPolicyDetails, setSubmitType, manualPolicyDetails, policyDetails]);

  const addManualAllocation = useCallback(async () => {
    let payload = {
      unallocated_amt: 0,
      allocated_amt: 0,
      receivable_amt: 0,
      XFIbatchno: 0,
      allocation_date: null,
      allocation_entity: manualPolicyDetails?.Producing_Entity || "-",
      allocation_status: "In Progress - CC",
      accounting_monthyear: transaction?.Accounting_Month ? format(
        new Date(transaction.Accounting_Month),
        "yyyy-MM-dd"
      ) : null,
      allocation_systemid: "-",
      bank_txn: transaction.id,
      cash_reference: null,
      GXPbatchno: null,
      allocatedby: null,
      bank_curr: transaction?.bank_currency ?? null,
      payment_currency: transaction?.payment_currency ?? null,
      original_ccy: manualPolicyDetails?.original_ccy ?? null,
      settlement_currency: manualPolicyDetails?.settlement_currency ?? null,
      installment_amount_org: manualPolicyDetails?.installment_amount_org ?? null,
      bank_roe: transaction?.bank_roe || 0,
      allocation_eea: manualPolicyDetails?.allocation_eea,
      allocation_invoice_verification:
        manualPolicyDetails?.allocation_invoice_verification,
      policy_id: manualPolicyDetails?.Policy_Line_Ref,
      allocation_binder: manualPolicyDetails?.Syndicate_Binder,
      allocation_umr: manualPolicyDetails?.UMR_Number,
      binding_agreement: manualPolicyDetails?.Binding_Agreement,
      allocation_scm:
        manualPolicyDetails.SCM_Partner === ""
          ? null
          : manualPolicyDetails.SCM_Partner,
      allocation_scmname:
        manualPolicyDetails.SCM_Insurer_partner_name === ""
          ? null
          : manualPolicyDetails.SCM_Insurer_partner_name,
      isActive: true,
      manual_entry: true,
      Third_Party_Capacity: manualPolicyDetails.Binding_Agreement === "SCM" ? "Yes" : "No",
      audit_fields: {
        user_id: userData?.id,
        fields: [
          {
            field_name: "policy_id",
            old_value: null,
            value: manualPolicyDetails?.Policy_Line_Ref
          },
          {
            field_name: "allocation_status",
            old_value: null,
            value: "In Progress - CC"
          },
          {
            field_name: "receivable_amt",
            old_value: null,
            value: null
          },
          {
            field_name: "allocated_amt",
            old_value: null,
            value: null
          }
        ],
        txn_id: null,
        audit_txn_name: "CASH_ALLOCATION"
      }
    };

    try {
      // create poilcy manually
      const createPolicyResponse = await createPolicy();

      // Allocate information to Policy
      if (createPolicyResponse?.data?.Policy_Line_Ref === manualPolicyDetails?.Policy_Line_Ref) {
        payload.policy_pk = createPolicyResponse?.data?.id;
        return instance
          .post(`bankmanagement/cash_allocation/`, payload)
          .then((response) => {
            const data = response.data;
            setPolicyDetails(data);
            setSubmitType("add");
            onSuccess(data);
          })
          .catch((error) => {
            toast.error("Failed to Add New Manual Allocation", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            console.error("Error fetching bank details:", error);
          });
      } else {
        toast.error("Failed to Create a New Policy before Manual Allocation", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        return createPolicyResponse;
      }
    } catch (err) {
      toast.error("Failed to Create a New Policy before Manual Allocation", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }

  }, [setPolicyDetails, setSubmitType, manualPolicyDetails, policyDetails]);

  return {
    policyDetails,
    searchPolicy,
    addPolicy,
    addAllocation,
    addManualAllocation,
    createPolicy,
  };
};

export const useAllocationList = (transactionId) => {
  const [allocationList, setAllocationList] = useState([]);
  const [totalAllocatedAmount, setTotalAllocatedAmount] = useState(0);
  const [totalReceivableAmount, setTotalReceivableAmount] = useState(0);
  const [apiProgressBar, setApiProgressBar] = useState(false);
  const { userData } = useSelector((state) => state?.user);

  const loadData = useCallback(async () => {
    setApiProgressBar(true)
    try {
      setApiProgressBar(true);
      // Fetch bank details based on the transaction ID
      const response = await instance
        .get(
          `bankmanagement/get_transactions_by_txn_id/?txn_id=${transactionId}`
        )
      let data = response.data;
      // disable the row then when the allocation status is "Allocated"
      data = data?.map((item) => {
        return {
          ...item,
          disabled: item?.allocation_status === "Allocated",
          audit_fields:{
            user_id: userData?.id,
            fields: [
              {
                field_name: "policy_id",
                old_value: item?.policy_id,
                value: item?.policy_id
              },
              {
                field_name: "allocation_status",
                old_value: item?.allocation_status,
                value: item?.allocation_status
              },
              {
                field_name: "receivable_amt",
                old_value: item?.receivable_amt,
                value: item?.receivable_amt
              },
              {
                field_name: "allocated_amt",
                old_value: item?.allocated_amt,
                value: item?.allocated_amt
              }
            ],
            txn_id: item?.audit_txn?.id,
            audit_txn_name: "CASH_ALLOCATION"
          }
        }
      });
      setAllocationList([...data]);
      setApiProgressBar(false);
    } catch (err) {
      setApiProgressBar(false)
      console.log("err", err);
    }

  }, [setAllocationList, transactionId]);

  useEffect(() => {
    setTotalAllocatedAmount(
      // CMT-28
      allocationList.reduce((total, current) => {
        if (current.allocation_status === "Allocated") {
          return total + (isNaN(current.allocated_amt) ? 0 : +current.allocated_amt);
        }
        return total;
      }, 0)
    );
    setTotalReceivableAmount(
      allocationList.reduce((total, current) => {
        return total + (isNaN(current.receivable_amt) ? 0 : +current.receivable_amt);
      }, 0)
    );
  }, [allocationList]);

  useEffect(() => {
    if (!transactionId) return;
    loadData();
  }, [transactionId]);

  const saveAllocationData = useCallback((payload) => {
    return instance.put(
      `bankmanagement/cash_allocation/${payload.id}/`,
      payload
    );
  });

  const deleteAllocationData = useCallback((id) => {
    return instance.delete(`bankmanagement/cash_allocation/${id}/`);
  });

  return {
    loadData,
    allocationList,
    setAllocationList,
    saveAllocationData,
    deleteAllocationData,
    totalAllocatedAmount,
    setTotalAllocatedAmount,
    totalReceivableAmount,
    apiProgressBar,
    setApiProgressBar,
  };
};

export const useUserData = () => {
  const [details, setDetails] = useState([]);

  useEffect(() => {
    // Fetch dropdown values from your API
    instance
      .get(`users/users_api/user/`)
      .then((response) => setDetails(response.data))
      .catch((error) => console.error("Error fetching user data:", error));
  }, []);
  return details;
};

export const useBrockerData = () => {
  const [dropdownValues, setDropdownValues] = useState([]);

  useEffect(() => {
    // Fetch dropdown values from your API
    instance
      .get(`documents/get_broker_list/`)
      .then((response) => setDropdownValues(response.data.Broker_Branch_Name))
      .catch((error) => console.error("Error fetching brocker data:", error));
  }, []);
  return dropdownValues;
};

export const useCurrencies = () => {
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    // Fetch dropdown values from your API
    instance
      .get(`documents/currency_details/`)
      .then((response) => {
        const data = response?.data ?? [];
        
        // Make unique based on 'currency_code'
        const uniqueCurrencies = Array.from(
          new Map(data.map((item) => [item.currency_code, item])).values()
        );
        
        setCurrencies(uniqueCurrencies);
      })
      .catch((error) =>
        console.error("Error fetching dropdown values:", error)
      );
  }, []);
  return currencies;
};

export const useAllocationStatusList = () => {
  const [allocationStatusList, setAllocationStatusList] = useState([]);

  useEffect(() => {
    // Fetch dropdown values from your API
    instance
      .get(`documents/allocation_status/`)
      .then((response) => {
        const data = response?.data ?? [];
        
        // Make unique based on 'currency_code'
        const uniqueAllocationStatusList = Array.from(
          new Map(data.map((item) => [item.allocation_status, item])).values()
        );
        
        setAllocationStatusList(uniqueAllocationStatusList);
      })
      .catch((error) =>
        console.error("Error fetching dropdown values:", error)
      );
  }, []);

  return allocationStatusList;
};

// Hook to Retrieve bank details based on certain Filters
export const useBankDetailsListForFilter = (fiterPayload) => {
  const [bankDetailsList, setBankDetailsList] = useState([]);

  useEffect(() => {
    // Fetch dropdown values from your API
    instance
      .post(`documents/get_bank_details_for_filters/`, fiterPayload)
      .then((response) => {
        const data = response?.data ?? [];
        setBankDetailsList(data);
      })
      .catch((error) =>
        console.error("Error fetching dropdown values:", error)
      );
  }, []);

  return bankDetailsList;
};

export const useCfi = (allocation, id) => {
  const [history, setHistory] = useState([]);
  const [isApiInProgress, setIsApiInProgress] = useState(false);

  const { userData } = useSelector((state) => state?.user);

  const [formData, setFormData] = useState({
    aprover_name: allocation?.aprover_name || "",
    // system_id: allocation?.allocation_systemid,
    // accounting_monthyear: allocation?.accounting_monthyear,
    // request_date: parseDate(new Date()),
    // policy_id: allocation?.policy_id,
  });
  const setCurrencies = () => {};

  const loadHistory = useCallback(() => {
    instance
      .get(
        `bankmanagement/get_cfi_by_allocation_id?cashallocation_id=${allocation?.id}`
      )
      .then((response) => setHistory(response.data))
      .catch((error) =>
        console.error("Error fetching dropdown values:", error)
      );
  }, [setHistory]);

  useEffect(() => {
    loadHistory();
  }, []);

  const submit = useCallback(() => {
    setIsApiInProgress(true);
    return instance[id ? "put" : "post"](
      `bankmanagement/cash_allocation_cfi/${id ? id : ""}`,
      {
        ...formData,
        bank_txn: allocation?.bank_txn?.id,
        cash_allocation: allocation?.id,
        aprover_name: formData.aprover_name || "",
        // policy_id: allocation?.policy_id,
        // id: id || undefined,
      }
    )
      .then((response) => {
        setCurrencies(response.data);
        if (response?.data?.id) {
          const reqBody = {
            Receivable_Amount: 0,
            initiater: userData?.user_name,
            bank_txn_id: response?.data?.id,
            comments: formData.cfi_change ?? "",
            initiated_user_id: userData?.id,
            workflow_name: "WF_CFI",
          };

          return instance
            .post(workflowBankTransaction, reqBody)
            .then((response) => {
              setIsApiInProgress(false);
              if (response) {
                toast.success("Sent for Approval !!", {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                });
              }
              return {
                shouldShowHistory: true
              }
            })
            .catch((error) => {
              setIsApiInProgress(false);
              toast.error("Failed to Approve the Work Flow !!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
              console.error("Error fetching dropdown values:", error);
              return {
                shouldShowHistory: false
              }
            });
        }
        else {
          return {
            shouldShowHistory: true
          }
        }
      })
      .catch((error) => {
        setIsApiInProgress(false);
        console.error("Error fetching dropdown values:", error);
        return {
          shouldShowHistory: false
        }
      });
  }, [formData, allocation, id]);

  return {
    history,
    formData,
    setFormData,
    submit,
    loadHistory,
    isApiInProgress,
  };
};

export const useCrossAllocation = (allocation, id) => {
  const [history, setHistory] = useState([]);
  const [isApiInProgress, setIsApiInProgress] = useState(false);
  
  const { userData } = useSelector((state) => state?.user);

  const [formData, setFormData] = useState({
    aprover_name: allocation?.aprover_name || "",
    // system_id: allocation?.allocation_systemid,
    // accounting_monthyear: allocation?.accounting_monthyear,
    // request_date: parseDate(new Date()),
    // policy_id: allocation?.policy_id,
  });
  const setCurrencies = () => {};

  const loadHistory = useCallback(() => {
    instance
      .get(
        `bankmanagement/get_cross_allocation_by_allocation_id?cashallocation_id=${allocation?.id}`
      )
      .then((response) => setHistory(response.data))
      .catch((error) =>
        console.error("Error fetching dropdown values:", error)
      );
  }, [setHistory]);

  useEffect(() => {
    loadHistory();
  }, []);

  const submit = useCallback(() => {
    setIsApiInProgress(true);
    return instance[id ? "put" : "post"](
      `bankmanagement/cross_allocation/${id ? id : ""}`,
      {
        ...formData,
        bank_txn: allocation?.bank_txn?.id,
        cash_allocation: allocation?.id,
        aprover_name: formData.aprover_name || "",
        // policy_id: allocation?.policy_id,
        // id: id || undefined,
      }
    )
      .then((response) => {
        setCurrencies(response.data);

        if (response?.data?.id) {
          const reqBody = {
            Receivable_Amount: 0,
            initiater: userData?.user_name,
            bank_txn_id: response?.data?.id,
            comments: formData.ca_change,
            initiated_user_id: userData?.id,
            workflow_name: "WF_CROSS_ALLOCATION",
          };

          return instance
            .post(workflowBankTransaction, reqBody)
            .then((response) => {
              setIsApiInProgress(false);
              if (response) {
                toast.success("Sent for Approval !!", {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                });
              }
              return {
                shouldShowHistory: true
              }
            })
            .catch((error) => {
              setIsApiInProgress(false);
              toast.error("Failed to Approve the Work Flow !!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
              console.error("Error fetching dropdown values:", error);
              return {
                shouldShowHistory: false
              }
            });
        }
        else {
          return {
            shouldShowHistory: true
          }
        }
      })
      .catch((error) => {
        setIsApiInProgress(false);
          console.error("Error fetching dropdown values:", error);
          return {
            shouldShowHistory: false
          }
      });
  }, [formData, allocation, id]);
  return {
    history,
    formData,
    setFormData,
    submit,
    loadHistory,
    isApiInProgress,
  };
};

export const useMsd = (allocation, id) => {
  const [history, setHistory] = useState([]);
  const [formData, setFormData] = useState({});
  const setCurrencies = () => {};

  const loadHistory = useCallback(() => {
    instance
      .get(
        `bankmanagement/get_msd_by_allocation_id?cashallocation_id=${allocation?.id}`
      )
      .then((response) => setHistory(response.data))
      .catch((error) =>
        console.error("Error fetching dropdown values:", error)
      );
  }, [setHistory]);

  useEffect(() => {
    loadHistory();
  }, []);

  const submit = useCallback(() => {
    return instance[id ? "put" : "post"](
      `bankmanagement/cash_allocation_msd/${id ? id : ""}`,
      {
        ...formData,
        bank_txn: allocation?.bank_txn?.id,
        cash_allocation: allocation?.id,
        aprover_name: formData.aprover_name || "",
        // policy_id: allocation?.policy_id,
        // system_id: allocation?.allocation_systemid,
        // accounting_monthyear: allocation?.accounting_monthyear,
        // id: id || undefined,
      }
    )
      .then((response) => {
        setCurrencies(response.data);
        return loadHistory();
      })
      .catch((error) =>
        console.error("Error fetching dropdown values:", error)
      );
  }, [formData, allocation, id]);
  return {
    history,
    formData,
    setFormData,
    submit,
  };
};

export const useRefund = (allocation, id) => {
  const [history, setHistory] = useState([]);
  const [isApiInProgress, setIsApiInProgress] = useState(false);

  const { userData } = useSelector((state) => state?.user);

  const [formData, setFormData] = useState({
    aprover_name: allocation?.aprover_name || "",
    // request_date: parseDate(new Date()),
    // policy_id: allocation?.policy_id,
  });
  const setCurrencies = () => {};

  const loadHistory = useCallback(() => {
    instance
      .get(
        `bankmanagement/get_refund_by_allocation_id?cashallocation_id=${allocation?.id}`
      )
      .then((response) => setHistory(response.data))
      .catch((error) =>
        console.error("Error fetching dropdown values:", error)
      );
  }, [setHistory]);

  useEffect(() => {
    loadHistory();
  }, []);

  const submit = useCallback(() => {
    setIsApiInProgress(true);
    return instance[id ? "put" : "post"](
      `bankmanagement/cash_allocation_refund/${id ? id : ""}`,
      {
        ...formData,
        bank_txn: allocation?.bank_txn?.id,
        cash_allocation: allocation?.id,
        aprover_name: formData.aprover_name || "",
        // policy_id: allocation?.policy_id,
        // system_id: allocation?.allocation_systemid,
        // accounting_monthyear: allocation?.accounting_monthyear,
        // id: id || undefined,
      }
    )
      .then((response) => {
        setCurrencies(response.data);

        if (response?.data?.id) {
          const reqBody = {
            Receivable_Amount: 0,
            initiater: userData?.user_name,
            bank_txn_id: response?.data?.id,
            comments: formData.comments,
            initiated_user_id: userData?.id,
            workflow_name: "WF_REFUND",
          };

          return instance
            .post(workflowBankTransaction, reqBody)
            .then((response) => {
              setIsApiInProgress(false);
              if (response) {
                toast.success("Sent for Approval !!", {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                });
              }
              return {
                shouldShowHistory: true
              }
            })
            .catch((error) => {
              setIsApiInProgress(false);
              toast.error("Failed to Approve the Work Flow !!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
              console.error("Error fetching dropdown values:", error);
              return {
                shouldShowHistory: false
              }
            });
        } else {
          return {
            shouldShowHistory: true
          }
        }
      })
      .catch((error) => {
        setIsApiInProgress(false);
        toast.error("Failed to Approve the Work Flow !!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        console.error("Error fetching dropdown values:", error);
        return {
          shouldShowHistory: false
        }
      });
  }, [formData, allocation, id]);

  return {
    history,
    formData,
    setFormData,
    submit,
    loadHistory,
    isApiInProgress,
  };
};

export const useWriteOff = (allocation, id) => {
  const [history, setHistory] = useState([]);
  const [formData, setFormData] = useState({
    // request_date: parseDate(new Date()),
    // policy_id: allocation?.policy_id,
    // system_id: allocation?.allocation_systemid,
    // accounting_monthyear: allocation?.accounting_monthyear,
    // approved_date: allocation?.approved_date,
    aprover_name: "",
  });
  const [isApiInProgress, setIsApiInProgress] = useState(false);
  const { userData } = useSelector((state) => state?.user);

  const setCurrencies = () => { };
  
  const loadHistory = useCallback(() => {
    setIsApiInProgress(true);
    instance
      .get(
        `bankmanagement/get_writeoff_by_allocation_id?cashallocation_id=${allocation?.id}`
      )
      .then((response) => setHistory(response.data))
      .catch((error) =>
        console.error("Error fetching dropdown values:", error)
      );
    setIsApiInProgress(false);
  }, [setHistory]);

  useEffect(() => {
    loadHistory();
  }, []);

  const submit = useCallback(() => {
    setIsApiInProgress(true);
    return instance[id ? "put" : "post"](
      `bankmanagement/cash_allocation_writeoff/${id ? id : ""}`,
      {
        ...formData,
        bank_txn: allocation?.bank_txn?.id,
        cash_allocation: allocation?.id,
        aprover_name: formData.aprover_name || "",
      }
    )
      .then((response) => {
        // setIsApiInProgress(false);
        setCurrencies(response.data);

        if (response?.data?.id) {
          const reqBody = {
            Receivable_Amount: 0,
            initiater: userData?.user_name,
            bank_txn_id: response?.data?.id,
            comments: formData.comments,
            initiated_user_id: userData?.id,
            workflow_name: "WF_WRITEOFF",
          };

          return instance
            .post(workflowBankTransaction, reqBody)
            .then((response) => {
              setIsApiInProgress(false);
              if (response) {
                toast.success("Sent for Approval !!", {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                });
                // return loadHistory();
                return {
                  shouldShowHistory: true
                }
              }
            })
            .catch((error) => {
              // setIsApiInProgress(false);
              toast.error("Failed to Approve the Work Flow !!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
              console.error("Error fetching dropdown values:", error);
              return {
                shouldShowHistory: false
              }
            });
        }else{
          return {
            shouldShowHistory: true
          }
        }
      })
      .catch((error) => {
        // setIsApiInProgress(false);
        toast.error("Failed to Approve the Work Flow !!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        console.error("Error fetching dropdown values:", error);
        return {
          shouldShowHistory: false
        }
      });
  }, [formData, allocation, id]);

  // Create Contra Associated Write-off
  const createContraWriteOff = useCallback((currentContraEntry, allocationId) => {
    return instance["post"](
      `bankmanagement/cash_allocation_writeoff/`,
      {
        ...currentContraEntry,
        bank_txn: currentContraEntry?.bank_txn.id,
        cash_allocation: allocationId,
        writeoff_amt: -currentContraEntry?.writeoff_amt,
        aprover_name: formData.aprover_name || ""
      }
    )
      .then((response) => {
        toast.success("Contra Writeoff Created Sucessfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      })
      .catch((error) => {
        toast.error("Failed to create Contra Writeoff", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  }, []);

  return {
    history,
    formData,
    setFormData,
    submit,
    isApiInProgress,
    loadHistory,
    createContraWriteOff
  };
};

export const useAllocationIssues = (allocation, transaction, id) => {
  const [history, setHistory] = useState([]);
  const { userData } = useSelector((state) => state?.user);
  const [isApiInProgress, setIsApiInProgress] = useState(false);

  const [formData, setFormData] = useState({
    issue_date: format(new Date(), "yyyy-MM-dd"),
    age_days: getAgeDays(
      transaction.Payment_Receive_Date,
      Number(allocation?.unallocated_amt)
    ),
    // accounting_monthyear: getAccMonthYear(
    //   new Date(allocation?.accounting_monthyear)
    // ),
    // system_correction: allocation?.allocation_systemid,
  });
  const setCurrencies = () => {};

  const loadHistory = useCallback(() => {
    instance
      .get(
        `bankmanagement/get_issues_by_allocation_id?cashallocation_id=${allocation?.id}`
      )
      .then((response) => setHistory(response.data))
      .catch((error) =>
        console.error("Error fetching dropdown values:", error)
      );
  }, [setHistory]);

  useEffect(() => {
    loadHistory();
  }, []);

  const submit = useCallback(() => {
    setIsApiInProgress(true);
    return instance[id ? "put" : "post"](
      `bankmanagement/cash_allocation_issues/${id ? id : ""}`,
      {
        ...formData,
        bank_txn: allocation?.bank_txn?.id,
        cash_allocation: allocation?.id,
        aprover_name: formData.aprover_name || "",
        // policy_id: allocation?.policy_id,
        // system_id: allocation?.allocation_systemid,
        // id: id || undefined,
      }
    )
      .then((response) => {
        setCurrencies(response.data);

        if (response?.data?.id && formData?.issue_category !== "Premium Payment") {
          const reqBody = {
            Receivable_Amount: 0,
            initiater: userData?.user_name,
            bank_txn_id: response?.data?.id,
            comments: formData.comments,
            initiated_user_id: userData?.id,
            workflow_name: "WF_CORRECTION_TYPES",
          };

          return instance
            .post(workflowBankTransaction, reqBody)
            .then((response) => {
              setIsApiInProgress(false);
              if (response) {
                toast.success("Sent for Approval !!", {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                });
                return {
                  shouldShowHistory: true
                }
              }
            })
            .catch((error) => {
              setIsApiInProgress(false);
              toast.error("Failed to Approve the Work Flow !!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
              console.error("Error fetching dropdown values:", error);
              return {
                shouldShowHistory: false
              }
            });
        } else {
          setIsApiInProgress(false)
          toast.success("Correction Type Created Sucessfully !!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          return {
            shouldShowHistory: true
          }
        }
      })
      .catch((error) => {
        setIsApiInProgress(false);
        toast.error("Failed to Approve the Work Flow !!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        console.error("Error fetching dropdown values:", error);
        return {
          shouldShowHistory: false
        }
      });
  }, [formData, allocation, id]);
  return {
    history,
    formData,
    setFormData,
    submit,
    loadHistory,
    isApiInProgress,
  };
};

export const useTreasuryUpdate = (allocation, id) => {
  const [history, setHistory] = useState([]);
  const [formData, setFormData] = useState({});
  const setCurrencies = () => {};

  const loadHistory = useCallback(() => {
    instance
      .get(
        `bankmanagement/get_premium_payment_by_allocation_id?cashallocation_id=${allocation?.id}`
      )
      .then((response) => setHistory(response.data))
      .catch((error) =>
        console.error("Error fetching dropdown values:", error)
      );
  }, [setHistory]);

  useEffect(() => {
    loadHistory();
  }, []);

  const submit = useCallback(() => {
    return instance[id ? "put" : "post"](
      `bankmanagement/premium_payment/${id ? id : ""}`,
      {
        ...formData,
        bank_txn: allocation?.bank_txn?.id,
        cash_allocation: allocation?.id,
        aprover_name: formData.aprover_name || "",
        // policy_id: allocation?.policy_id,
        // system_id: allocation?.allocation_systemid,
        // accounting_monthyear: allocation?.accounting_monthyear,
        // id: id || undefined,
      }
    )
      .then((response) => {
        setCurrencies(response.data);
        return loadHistory();
      })
      .catch((error) =>
        console.error("Error fetching dropdown values:", error)
      );
  }, [formData, allocation, id]);
  return {
    history,
    formData,
    setFormData,
    submit,
  };
};

export const useCorrectiveAlloction = (allocation, id) => {
  const [history, setHistory] = useState([]);
  const [formData, setFormData] = useState({});
  const setCurrencies = () => {};

  const loadHistory = useCallback(() => {
    instance
      .get(
        `bankmanagement/get_corrective_by_allocation_id?cashallocation_id=${allocation?.id}`
      )
      .then((response) => setHistory(response.data))
      .catch((error) =>
        console.error("Error fetching dropdown values:", error)
      );
  }, [setHistory]);

  useEffect(() => {
    loadHistory();
  }, []);

  const submit = useCallback(() => {
    return instance[id ? "put" : "post"](
      `bankmanagement/cash_allocation_corrective/${id ? id : ""}`,
      {
        ...formData,
        bank_txn: allocation?.bank_txn?.id,
        cash_allocation: allocation?.id,
        aprover_name: formData.aprover_name || "",
        // policy_id: allocation?.policy_id,
        // system_id: allocation?.allocation_systemid,
        // accounting_monthyear: allocation?.accounting_monthyear,
        // id: id || undefined,
      }
    )
      .then((response) => {
        setCurrencies(response.data);

        // if (response?.data?.id) {
        //   const reqBody = {
        //     Receivable_Amount: 0,
        //     initiater: userData?.user_name,
        //     bank_txn_id: response?.data?.id,
        //     comments: formData.comments,
        //     initiated_user_id: userData?.id,
        //     workflow_name: "WF_CORRECTIVE_TRANSFER"
        //   };

        //   instance.post(workflowBankTransaction, reqBody)
        //     .then((response) => {
        //       if(response) {
        //         toast.success('Sent for Approval !!', {
        //           position: toast.POSITION.TOP_RIGHT,
        //           autoClose: 1000,
        //         });
        //         return loadHistory();
        //       }
        //     }).catch((error) => {
        //       toast.error("Failed to Approve the Work Flow !!", {
        //         position: toast.POSITION.TOP_RIGHT,
        //         autoClose: 1000,
        //       });
        //       console.error("Error fetching dropdown values:", error)
        //     });
        // }
      })
      .catch((error) => {
        toast.error("Failed to Approve the Work Flow !!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        console.error("Error useCorrectiveAlloction:", error);
      });
  }, [formData, allocation, id]);
  return {
    history,
    formData,
    setFormData,
    submit,
  };
};

const getTodaysDateInLocalTimezone = () => {
  const localDate = new Date(); // Local timezone
  return localDate.toISOString().split('T')[0]; // Returns date in "YYYY-MM-DD" format
};

export const useCorrectiveTrf = (allocation, transaction, id) => {
  const [history, setHistory] = useState([]);
  const [isApiInProgress, setIsApiInProgress] = useState(false);
  const { userData } = useSelector((state) => state?.user);

  const [formData, setFormData] = useState({
    comments: "",
    transfer_amt: allocation?.receivable_amt || 0,
    transfer_pt_bank_account_name: allocation?.policy_fk?.Producing_Entity || "",
    PT_bank_acct_Number: transaction?.bank_details?.account_number || transaction.Receiving_Bank_Account,
    treasury_confirmed_transfer_date: getTodaysDateInLocalTimezone(),
    bank_curr: allocation?.settlement_currency || null,
    request_date: parseDate(new Date()),
  });
  const setCurrencies = () => {};

  const loadHistory = useCallback(() => {
    instance
      .get(
        `bankmanagement/get_corrective_by_allocation_id?cashallocation_id=${allocation?.id}`
      )
      .then((response) => setHistory(response.data))
      .catch((error) =>
        console.error("Error fetching dropdown values:", error)
      );
  }, [setHistory]);

  useEffect(() => {
    loadHistory();
  }, []);

  const submit = useCallback(async () => {
    setIsApiInProgress(true);
    return instance[id ? "put" : "post"](
      `bankmanagement/cash_allocation_corrective/${id ? id : ""}`,
      {
        ...formData,
        bank_txn: allocation?.bank_txn?.id,
        cash_allocation: allocation?.id,
        aprover_name: formData.aprover_name || "",
        bank_curr: formData.bank_curr ?? allocation?.policy_fk?.Settlement_Ccy
        // policy_id: allocation?.policy_id,
        // system_id: allocation?.allocation_systemid,
        // accounting_monthyear: allocation?.accounting_monthyear,
        // id: id || undefined,
      }
    )
      .then((response) => {
        setCurrencies(response.data);
        if (response?.data?.id) {
          const reqBody = {
            Receivable_Amount: 0,
            initiater: userData?.user_name,
            bank_txn_id: response?.data?.id,
            comments: formData.comments,
            initiated_user_id: userData?.id,
            workflow_name: "WF_CORRECTIVE_TRANSFER",
          };

          return instance
            .post(workflowBankTransaction, reqBody)
            .then((response) => {
              setIsApiInProgress(false);
              if (response) {
                toast.success("Sent for Approval !!", {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                });
                return {
                  shouldShowHistory: true
                }
              }
            })
            .catch((error) => {
              setIsApiInProgress(false);
              toast.error("Failed to Approve the Work Flow !!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
              return {
                shouldShowHistory: false
              }
              console.error("Error fetching dropdown values:", error);
            });
        }
        else {
          return {
            shouldShowHistory: true
          }
        }
      })
      .catch((error) => {
        setIsApiInProgress(false);
        console.error("Error fetching dropdown values:", error);
        return {
          shouldShowHistory: false
        }
      });
  }, [formData, allocation, id]);

  return {
    history,
    formData,
    setFormData,
    submit,
    loadHistory,
    isApiInProgress,
  };
};

export const useFileUpload = (transaction) => {
  const [history, setHistory] = useState([]);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    setHistory(transaction.document_files || []);
  }, [transaction.document_files]);

  const submit = useCallback(async () => {
    const uploadFilesPromises = formData?.file?.map((currentFileData, index) => {
      const form = new FormData();
      form.append("transaction_id", transaction.id);
      form.append("document_name", currentFileData?.name);
      form.append("document_details", formData?.document_details);
      form.append("File_Name", currentFileData);

      return instance.post(
        `bankmanagement/multi_file_upload_to_transactions/`,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    });

    const results = await Promise.allSettled(uploadFilesPromises);

    results?.forEach((result, index) => {
      const { status, value} = result;

      if (status === "fulfilled") {
        const uploadedFileName = value?.config?.data?.get("document_name");
        toast.success(
          `${uploadedFileName} Files uploaded successfully!`,
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          }
        );
        if (index === results.length - 1) {
          setHistory(value?.data?.document_files || []);
        }
      } else {
        toast.error(
          result?.reason?.data?.message ??
          `Error uploading file`,
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          }
        );
      }
    })

  }, [formData, transaction?.id]);
  
  return {
    history,
    formData,
    setFormData,
    submit,
  };
};

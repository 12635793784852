import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@mui/material";
import dayjs from "dayjs";

//inline styles
const InlineStyles = {
  TableHeader: {
    backgroundColor: "#262671",
    color: "white",
    position: "sticky",
    top: 0,
    zIndex: 1,
    height: "50px",
  },
  TableCells: {
    color: "black",
    fontSize: "12px",
    height: "44px",
    padding: "0px",
  },
  TableBodyRows: {
    border: "1px solid #ddd",
    marginTop: "10px",
    paddingTop: "15px",
    verticalAlign: "top",
  },
  TableBodyRowCells: {
    height: "20px",
    padding: "0px",
    textAlign: "center",
  },
};

function handlePercentage(val_array) {
  const options = {
    style: "decimal",
    minimumFractionDigits: 8,
    maximumFractionDigits: 8,
  };

  return val_array.map(
    (ele) =>
      (Number(ele) && ele.toLocaleString("en-US", options)) ||
      (ele === 0 && Number(ele).toFixed(8)) ||
      ele
  );
}

function handleAmount(val_array) {
  const options = {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  return val_array.map(
    (ele) =>
      (Number(ele) && ele.toLocaleString("en-US", options)) ||
      (ele === 0 && Number(ele).toFixed(2)) ||
      ele
  );
}

function handleROE(val_array) {
  const options = {
    style: "decimal",
    minimumFractionDigits: 6,
    maximumFractionDigits: 6,
  };

  return val_array.map(
    (ele) =>
      (Number(ele) && ele.toLocaleString("en-US", options)) ||
      (ele === 0 && Number(ele).toFixed(6)) ||
      ele
  );
}

function handleFilename(filename_array) {
  return filename_array.map((ele) => ele.slice(0, -5));
}

function handleDate(date_array) {
  return date_array.map((ele) => dayjs(ele, "YYYY-MM-DD").format("DD-MM-YYYY"));
}

const GetViewTable = ({
  selectedExcelSheetJson,
  setSelectedExcelSheetJson,
  setFilenameArray,
  setCertificateRefArray,
  currentTotalFNP,
  currentSelectedSheetRowCount,
}) => {
  const [jsonRecords, setJsonRecords] = useState([]);

  const range = Array.from(
    { length: Object.values(selectedExcelSheetJson)[0].length },
    (value, index) => index
  );
  useEffect(() => {
    let json = JSON.parse(JSON.stringify(selectedExcelSheetJson));
    Object.keys(json).forEach((key) => {
      const lower_key = key.replace(/\s+/g, "").toLowerCase();
      if (
        lower_key.includes("percent") ||
        lower_key.includes("%") ||
        lower_key.includes("commissionper") ||
        lower_key.includes("brokpremper")
      ) {
        json[key] = handlePercentage(json[key]);
      } else if (
        lower_key.includes("amount") ||
        lower_key.includes("premium") ||
        lower_key.includes("currency") ||
        lower_key.includes("tax") ||
        lower_key.includes("accessori")
      ) {
        json[key] = handleAmount(json[key]);
      } else if (lower_key.includes("rateofexchange")) {
        json[key] = handleROE(json[key]);
      } else if (lower_key.includes("filename")) {
        json[key] = handleFilename(json[key]);
      } else if (lower_key.includes("date")) {
        json[key] = handleDate(json[key]);
      }
    });
    setJsonRecords(json);

  }, [selectedExcelSheetJson]);

  useEffect(() => {
    if (selectedExcelSheetJson["filename"]) {
      console.log("inside filename")
      console.log(selectedExcelSheetJson["filename"])
      setFilenameArray(selectedExcelSheetJson["filename"]);
    }

    if (selectedExcelSheetJson["certificateref"]) {
      console.log("inside certificateref")
      console.log(selectedExcelSheetJson["certificateref"])
      setCertificateRefArray(selectedExcelSheetJson["certificateref"]);
    }
  }, [selectedExcelSheetJson, setCertificateRefArray, setFilenameArray])

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', marginBottom: "15px" }}>
        <Grid>
          <span>Total Final Net Premium (Settlement Currency): {currentTotalFNP}</span>
        </Grid>
        <Grid style={{ position: "absolute", right: "50px" }}>
          Count: {currentSelectedSheetRowCount}
        </Grid>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableContainer
            style={{ height: "400px", overflowY: "auto", }}
          >
            <Table stickyHeader>
              <TableHead >
                <TableRow >
                  {Object.keys(jsonRecords).map((header, index) => (
                    <TableCell key={index} className="TableCell" style={{ textAlign: "center", backgroundColor: "#FFC000", }}>
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody >
                {range.map((index) => (
                  <TableRow key={index} style={InlineStyles.TableBodyRows}>
                    {Object.keys(jsonRecords).map((key, cellIndex) => (
                      <TableCell key={cellIndex} sx={InlineStyles.TableBodyRowCells}>
                        {jsonRecords[key][index]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default GetViewTable;

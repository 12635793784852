import React, { useCallback, useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Input, MenuItem, Select, Tooltip, Typography } from '@mui/material'
import CloseIcon from "@mui/icons-material/Close";
import { CommonSelect } from '../common';
import { inputStyleObj, selectStyleObj } from "./WriteOff";
import { acctMonthToLabel, useCurrencies } from './hooks';
import { CommonButton } from '../common/commonButton';
import instance from '../../redux/api';
import { parseDate } from './utils';
import Cookies from "js-cookie";
import { Box, Stack } from '@mui/system';
import { systemIdList } from './constants';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PersonIcon from '@mui/icons-material/Person';
import { toast } from 'react-toastify';

const Container = {
  flexGrow: 1,
  justifyContent: "flex-end",
  alignItems: "flex-end",
  display: "flex",
};

const CashAllocationEdit = ({
  policy,
  isOpen,
  onCancel,
  loadData
}) => {
  const [bindingAgreementData, setbindingAgreementData] = useState([]);
  const [producingEntityData, setProducingEntityData] = useState([]);
  const [SCMPartnerNameData, setSCMPartnerNameData] = useState([]);
  const [confirmDialogBox, setConfirmDialogBox] = useState(false);
  const [addValidationError, setAddValidationError] = useState("");
  const [productingEntityDialog, setProductingEntityDialog] = useState(false);
  const [pendingProducingEntity, setPendingProducingEntity] = useState({});
  const [changedFields, setChangedFields] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const [manualPolicyDetails, setManualPolicyDetails] = useState({
    Producing_Entity: policy?.policy_fk?.Producing_Entity || "",
    Syndicate_Binder: policy?.allocation_binder || "",
    Policy_id: policy?.id || "",
    Policy_Line_Ref: "",
    UMR_Number: policy?.allocation_umr || "",
    Third_Party_Capacity: policy?.binding_agreement === "SCM" ? "Yes" : "No",
    SCM_Partner: policy?.allocation_scm === "nan" ? "" : policy?.allocation_scm || "",
    SCM_Insurer_partner_name: "",
    Binding_Agreement: policy?.binding_agreement || "",
    original_ccy: policy?.policy_fk?.Original_Ccy || "",
    settlement_currency: policy?.policy_fk?.Settlement_Ccy || "",
    installment_amount_org: policy?.installment_amount_org,
    allocation_eea: policy?.allocation_eea?.toUpperCase() ?? (
      (policy?.policy_fk?.Producing_Entity === "MEEA" ||
        policy?.policy_fk?.Producing_Entity === "MEU2")
        ? "EEA"
        : ""
    ),
    allocation_invoice_verification: policy?.allocation_invoice_verification || "",
    XFI_Batch_Number: policy?.XFIbatchno || "",
    GXB_Batch_Number: policy?.GXPbatchno || "",
    Accounting_Month: policy?.accounting_monthyear
      ? acctMonthToLabel(policy?.accounting_monthyear)
      : "",
    Allocated_Date: policy?.allocation_date
      ? parseDate(new Date(policy?.allocation_date))
      : "",
    system: policy?.system || "",
    Roe: policy?.bank_roe || "",
    Cash_Reference: policy?.cash_reference || "",
    System_Selected: policy?.allocation_systemid || ""
  });

  const currencies = useCurrencies();

  const handlePolicyDetailsChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      // changed UMR Number must be same as Age file UMR Number
      if (policy?.manual_entry === false) {
        if (name === "UMR_Number") {
          if (value !== policy?.policy_fk?.UMR_Number) {
            toast.error("Changed UMR Number must be same as Age file UMR Number", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            return;
          }
        }
        if (name === "Binding_Agreement") {
          if (value !== policy?.policy_fk?.Binding_Agreement) {
            toast.error("Changed Binding Agreement must be same as Age file Binding Agreement", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            return;
          }
        }
        if (name === "original_ccy") {
          if (value !== policy?.policy_fk?.Original_Ccy) {
            toast.error("Changed Original Currency must be same as Age file Original Currency", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            return;
          }
        }
        if (name === "installment_amount_org") {
          if (value !== policy?.policy_fk?.Installment_Agency_Amount_in_Orig) {
            toast.error("Changed Original Amount must be same as Age file Original Amount", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            return;
          }
        }
        

        if (name === "settlement_currency") {
          if (value !== policy?.policy_fk?.Settlement_Ccy) {
            toast.error("Changed Settlement Currency must be same as Age file Settlement Currency", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            return;
          }
        }
      }

      setManualPolicyDetails((d) => ({
        ...d,
        [name]: value,
      }));
      setChangedFields((prevFields) => ({
        ...prevFields,
        [name]: true, // Mark this field as changed
      }));

      if (name === "Binding_Agreement") {
        if (value === "1609") {
          setManualPolicyDetails((d) => ({
            ...d,
            SCM_Partner: "",
            SCM_Insurer_partner_name: "",
          }));
        }
        setManualPolicyDetails((d) => ({
          ...d,
          Third_Party_Capacity: value === "SCM" ? "Yes" : "No",
        }));
      }

      if (name === "Syndicate_Binder") {
        const regex = /^(M)[A-Z0-9]{12}$/;
        if (!regex.test(value)) {
          setAddValidationError(
            'Master Binder must start with "M" characters only and length should be 12 characters.'
          );
        } else {
          setAddValidationError("");
        }
      }
    },
    [setManualPolicyDetails, setChangedFields, policy]
  );

  const handleEntityNameDropdownChange = (name, value, reason, event) => {
    if (policy?.manual_entry === false) {
      if (name === "Producing_Entity") {
        if (value !== policy?.policy_fk?.Producing_Entity) {
          toast.error("Changed Producing Entity must be same as Age file Producing Entity", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          return;
        }
      }
    }

    if (reason === "clear") {
      setManualPolicyDetails({
        ...manualPolicyDetails,
        [name]: "",
      });
    }
    setManualPolicyDetails({
      ...manualPolicyDetails,
      [name]: value ?? "",
    });
    setChangedFields((prevFields) => ({
      ...prevFields,
      [name]: true
    }));
  };

  const handleSCMPartnerNameDropdownChange = (name, value, reason, event) => {
    if (policy?.manual_entry === false) {
      if (name === "SCM_Partner") {
        if (value !== policy?.policy_fk?.SCM_Partner) {
        toast.error("Changed SCM Partner Name must be same as Age file SCM Partner Name", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        return;
      }
    }
  }
    if (reason === "clear") {
      setManualPolicyDetails({
        ...manualPolicyDetails,
        [name]: ""
      });
    }
    setManualPolicyDetails({
      ...manualPolicyDetails,
      [name]: value
    });
    setChangedFields((prevFields) => ({
      ...prevFields,
      [name]: true
    }));
  };

  useEffect(() => {
    const fetchBindingAgreementData = async () => {
      try {
        const response = await instance.get('documents/binding_agreement/');
        setbindingAgreementData(response.data);
      } catch (error) {
        console.error('API call failed:', error);
      }
    };
    fetchBindingAgreementData();
  }, []);

  useEffect(() => {
    const fetchSCMPartnerNameData = async () => {
      try {
        const response = await instance.get('documents/scm_partners/');
        setSCMPartnerNameData(response.data);
      } catch (error) {
        console.error('API call failed:', error);
      }
    };
    fetchSCMPartnerNameData();
  }, []);

  useEffect(() => {
    const fetchProducingEntityData = async () => {
      try {
        const response = await instance.get('documents/entity/');
        setProducingEntityData(response.data);
      } catch (error) {
        console.error('API call failed:', error);
      }
    };
    fetchProducingEntityData();
  }, []);

  useEffect(() => {
    setManualPolicyDetails(prevDetails => ({
      ...prevDetails,
      Policy_id: policy?.id || "",
      Producing_Entity: policy?.policy_fk?.Producing_Entity || "",
      Syndicate_Binder: policy?.allocation_binder || "",
      Policy_Line_Ref: "",
      UMR_Number: policy?.allocation_umr || "",
      Third_Party_Capacity: policy?.binding_agreement === "SCM" ? "Yes" : "No",
      SCM_Partner: policy?.allocation_scm === "nan" ? "" : policy?.allocation_scm || "",
      SCM_Insurer_partner_name: "",
      Binding_Agreement: policy?.binding_agreement || "",
      original_ccy: policy?.policy_fk?.Original_Ccy || "",
      settlement_currency: policy?.policy_fk?.Settlement_Ccy || "",
      installment_amount_org: policy?.installment_amount_org,
      allocation_eea: policy?.allocation_eea?.toUpperCase() ?? (
        (policy?.policy_fk?.Producing_Entity === "MEEA" ||
          policy?.policy_fk?.Producing_Entity === "MEU2")
          ? "EEA"
          : ""
      ),
      allocation_invoice_verification: policy?.allocation_invoice_verification || "",
      XFI_Batch_Number: policy?.XFIbatchno || "",
      GXB_Batch_Number: policy?.GXPbatchno || "",
      Accounting_Month: policy?.accounting_monthyear
        ? acctMonthToLabel(policy?.accounting_monthyear)
        : "",
      Allocated_Date: policy?.allocation_date
        ? parseDate(new Date(policy?.allocation_date))
        : "",
      system: policy?.system || "",
      Roe: policy?.bank_roe || "",
      Cash_Reference: policy?.cash_reference || "",
      System_Selected: policy?.allocation_systemid || ""
    }));
  }, [policy]);

  const handleSave = async () => {

    setIsLoading(true)

    if (addValidationError) {
      setConfirmDialogBox(false)
      toast.error(addValidationError, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsLoading(false)
      return;
    }

    const url = `bankmanagement/cash_allocation_update/${policy?.id}/`;
    const updatedFields = Object.keys(changedFields).reduce((acc, key) => {
      if (changedFields[key]) { // Only add changed fields to the payload
        acc[key] = manualPolicyDetails[key];
      }
      return acc;
    }, {});

    let payload = {
      policy_pk: policy?.policy_fk?.id,
    };

    if (updatedFields['UMR_Number']) {
      payload.allocation_umr = updatedFields['UMR_Number'];
    }
    if (updatedFields['Syndicate_Binder']) {
      payload.allocation_binder = updatedFields['Syndicate_Binder'];
    }
    if (updatedFields['Binding_Agreement']) {
      payload.binding_agreement = updatedFields['Binding_Agreement'];
      payload.Three_Party_Capacity_Deployed = manualPolicyDetails?.Third_Party_Capacity;
    }
    if (updatedFields['SCM_Partner']) {
      payload.allocation_scm = updatedFields['SCM_Partner'];
    }
    if (updatedFields['Producing_Entity']) {
      payload.allocation_entity = updatedFields['Producing_Entity'];
    }
    if (updatedFields['allocation_eea']) {
      payload.allocation_eea = updatedFields['allocation_eea'];
    }
    if (updatedFields['allocation_invoice_verification']) {
      payload.allocation_invoice_verification = updatedFields['allocation_invoice_verification'];
    }
    if (updatedFields['XFI_Batch_Number']) {
      payload.XFIbatchno = updatedFields['XFI_Batch_Number'];
    }
    if (updatedFields['GXB_Batch_Number']) {
      payload.GXPbatchno = updatedFields['GXB_Batch_Number'];
    }
    if (updatedFields['original_ccy']) {
      payload.original_ccy = updatedFields['original_ccy'];
    }
    if (updatedFields['settlement_currency']) {
      payload.settlement_currency = updatedFields['settlement_currency'];
    }
    if (updatedFields['System_Selected']) {
      payload.allocation_systemid = updatedFields['System_Selected'];
    }
    if (updatedFields['Cash_Reference']) {
      payload.cash_reference = updatedFields['Cash_Reference'];
    }
    if (updatedFields['Roe']) {
      payload.bank_roe = updatedFields['Roe'];
    }
    if (updatedFields['installment_amount_org']) {
      payload.installment_amount_org = updatedFields['installment_amount_org'];
    }

    try {
      const response = await instance.patch(url, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
        },
      });
      if (response?.status === 200) {
        toast.success('Items updated successfully', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setConfirmDialogBox(false);
        onCancel();
        loadData();
      }
    } catch (error) {
      toast.error(error?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
    setIsLoading(false)
  }

  return (
    <div>
      <Dialog open={isOpen} onClose={onCancel} >
        <DialogTitle>Edit
          <Stack>
            <Typography sx={{ fontWeight: 'bold' }}>Policy Number: {policy?.policy_id}</Typography>
            <Typography sx={{ fontWeight: 'bold' }}>Allocation Status : {policy?.allocation_status}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <PersonIcon fontSize='20px' />
              <Typography sx={{ fontWeight: 'bold' }}>Manual Policy : {policy?.manual_entry ? "Yes" : "No"}</Typography>
            </Box>
          </Stack>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onCancel}
          style={{ position: "absolute", right: 12, top: 12 }}
        >
          <CloseIcon className="CloseIconStyle" />
        </IconButton>
        <DialogContent>
          <Grid container>
            <Grid item xs={6} md={6} paddingRight={1}>
              <p style={{ fontSize: 13, color: "#17191B" }}>Master Binder</p>
              <Input
                style={{ ...inputStyleObj }}
                name="Syndicate_Binder"
                onChange={handlePolicyDetailsChange}
                value={manualPolicyDetails.Syndicate_Binder}
                placeholder="Write details here"
                disabled={!policy?.manual_entry}
                required
              />
              {addValidationError && (
                <Typography style={{ color: "red", fontSize: 13 }}>
                  {addValidationError}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6} md={6} paddingLeft={1}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <p style={{ fontSize: 13, color: "#17191B" }}>
                  UMR
                </p>
                {
                  !policy?.manual_entry && <Tooltip title={policy?.policy_fk?.UMR_Number === null ? "Null" : policy?.policy_fk?.UMR_Number} arrow placement="right">
                    <InfoOutlinedIcon fontSize='16px' />
                  </Tooltip>
                }
              </Box>
              <Input
                style={{ ...inputStyleObj }}
                name="UMR_Number"
                onChange={handlePolicyDetailsChange}
                value={manualPolicyDetails.UMR_Number}
                placeholder="Write details here"
                disabled={!policy?.manual_entry}
                required
              />
            </Grid>
            <Grid item xs={6} md={6} paddingRight={1}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <p style={{ fontSize: 13, color: "#17191B" }}>
                  Binding Agreement
                </p>
                {
                  !policy?.manual_entry && <Tooltip title={policy?.policy_fk?.Binding_Agreement === null ? "Null" : policy?.policy_fk?.Binding_Agreement} arrow placement="right">
                    <InfoOutlinedIcon fontSize='16px' />
                  </Tooltip>
                }
              </Box>
              <Select
                style={{ ...selectStyleObj }}
                name="Binding_Agreement"
                onChange={handlePolicyDetailsChange}
                value={manualPolicyDetails.Binding_Agreement}
                placeholder="Write details here"
                disabled={!policy?.manual_entry}
                required
              >
                {bindingAgreementData?.map((option) => (
                  <MenuItem value={option.binding_aggrement_type} selected={manualPolicyDetails.Binding_Agreement === option.binding_aggrement_type}>{option.binding_aggrement_type}</MenuItem>
                ))}
                <MenuItem
                  value="NON SCM"
                  selected={manualPolicyDetails.Binding_Agreement === "NON SCM"}
                >
                  NON SCM
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={6} md={6} paddingLeft={1}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <p style={{ fontSize: 13, color: "#17191B" }}>
                  SCM Partner Name
                </p>
                {
                  !policy?.manual_entry && <Tooltip title={policy?.policy_fk?.SCM_Partner === null ? "Null" : policy?.policy_fk?.SCM_Partner} arrow placement="right">
                    <InfoOutlinedIcon fontSize='16px' />
                  </Tooltip>
                }
              </Box>
              <CommonSelect
                placeholder="Select SCM Partner Name"
                name="SCM_Partner"
                value={manualPolicyDetails?.SCM_Partner}
                required={manualPolicyDetails.Binding_Agreement === "SCM"}
                handleChange={(event, value, reason) =>
                  handleSCMPartnerNameDropdownChange(
                    "SCM_Partner",
                    value,
                    reason,
                    event
                  )
                }
                options={
                  SCMPartnerNameData?.map((option) => option?.partner_name) ||
                  []
                }
                disabled={!policy?.manual_entry}
              />
            </Grid>
            <Grid item xs={6} md={6} paddingRight={1}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <p style={{ fontSize: 13, color: "#17191B" }}>
                  Producing Entity
                </p>
                {
                  !policy?.manual_entry && <Tooltip title={policy?.policy_fk?.Producing_Entity === null ? "Null" : policy?.policy_fk?.Producing_Entity} arrow placement="right">
                    <InfoOutlinedIcon fontSize='16px' />
                  </Tooltip>
                }
              </Box>
              <CommonSelect
                placeholder="Select Producing Entity"
                name="Producing_Entity"
                value={manualPolicyDetails?.Producing_Entity}
                required
                handleChange={(event, value, reason) => {
                  setProductingEntityDialog(true);
                  setPendingProducingEntity({
                    name: "Producing_Entity",
                    value,
                    reason,
                    event
                  });
                }
                }
                options={
                  producingEntityData?.map((option) => option?.entity_divisions) ||
                  []
                }
                disabled={!policy?.manual_entry}
              />
            </Grid>
            <Grid item md={6} paddingLeft={1}>
              <p style={{ fontSize: 13, color: "#17191B" }}>EEA / NON EEA</p>

              <Select
                style={{ ...selectStyleObj }}
                name="allocation_eea"
                onChange={handlePolicyDetailsChange}
                value={manualPolicyDetails.allocation_eea}
                placeholder="Write details here"
                disabled={!policy?.manual_entry}
                required
              >
                <MenuItem value="EEA">EEA</MenuItem>
                <MenuItem value="NON EEA">NON EEA</MenuItem>
              </Select>
            </Grid>
            <Grid item md={6} paddingRight={1}>
              <p style={{ fontSize: 13, color: "#17191B" }}>
                Invoice Verification
              </p>

              <Select
                style={{ ...selectStyleObj }}
                name="allocation_invoice_verification"
                onChange={handlePolicyDetailsChange}
                value={manualPolicyDetails.allocation_invoice_verification}
                required
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </Grid>

            <Grid item md={6} paddingLeft={1}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <p style={{ fontSize: 13, color: "#17191B" }}>
                  Original Currency
                </p>
                {
                  !policy?.manual_entry && <Tooltip title={policy?.policy_fk?.Original_Ccy === null ? "Null" : policy?.policy_fk?.Original_Ccy} arrow placement="right">
                    <InfoOutlinedIcon fontSize='16px' />
                  </Tooltip>
                }
              </Box>
              <Select
                style={{ ...selectStyleObj }}
                name="original_ccy"
                onChange={handlePolicyDetailsChange}
                value={manualPolicyDetails.original_ccy}
                disabled={!policy?.manual_entry}
                required
              >
                {currencies?.map((option, optionIndex) => (
                  <MenuItem
                    key={`original_ccy_${option.currency_code}_${optionIndex}`}
                    value={option.currency_code}
                    selected={option.currency_code === manualPolicyDetails.original_ccy}
                  >
                    {option.currency_code}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item md={6} paddingRight={1}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <p style={{ fontSize: 13, color: "#17191B" }}>
                  Settlement Currency
                </p>
                {
                  !policy?.manual_entry && <Tooltip title={policy?.policy_fk?.Settlement_Ccy === null ? "Null" : policy?.policy_fk?.Settlement_Ccy} arrow placement="right">
                    <InfoOutlinedIcon fontSize='16px' />
                  </Tooltip>
                }
              </Box>
              <Select
                style={{ ...selectStyleObj }}
                name="settlement_currency"
                onChange={handlePolicyDetailsChange}
                value={manualPolicyDetails.settlement_currency}
                disabled={!policy?.manual_entry}
                required
              >
                {currencies?.map((option, optionIndex) => (
                  <MenuItem
                    key={`original_ccy_${option.currency_code}_${optionIndex}`}
                    value={option.currency_code}
                  >
                    {option.currency_code}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item md={6} paddingLeft={1}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <p style={{ fontSize: 13, color: "#17191B" }}>
                  Original Amount
                </p>
                {
                  !policy?.manual_entry && <Tooltip title={policy?.policy_fk?.Installment_Agency_Amount_in_Orig === null ? "Null" : policy?.policy_fk?.Installment_Agency_Amount_in_Orig} arrow placement="right">
                    <InfoOutlinedIcon fontSize='16px' />
                  </Tooltip>
                }
              </Box>
              <Input
                style={{ ...inputStyleObj }}
                name="installment_amount_org"
                pattern="\d+"
                onChange={handlePolicyDetailsChange}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                value={manualPolicyDetails.installment_amount_org}
                placeholder=""
                required
                type="number"
              />
            </Grid>
            <Grid item xs={6} md={6} paddingRight={1}>
              <p style={{ fontSize: 13, color: "#17191B" }}>XFI Batch Number</p>

              <Input
                style={{ ...inputStyleObj }}
                name="XFI_Batch_Number"
                onChange={handlePolicyDetailsChange}
                value={manualPolicyDetails.XFI_Batch_Number}
                placeholder="Write details here"
                required
              />
            </Grid>
            <Grid item xs={6} md={6} paddingLeft={1}>
              <p style={{ fontSize: 13, color: "#17191B" }}>GXB Batch Number</p>

              <Input
                style={{ ...inputStyleObj }}
                name="GXB_Batch_Number"
                onChange={handlePolicyDetailsChange}
                value={manualPolicyDetails.GXB_Batch_Number}
                placeholder="Write details here"
                required
              />
            </Grid>
            <Grid item xs={6} md={6} paddingRight={1}>
              <p style={{ fontSize: 13, color: "#17191B" }}>System</p>
              <select
                name="System_Selected"
                onChange={handlePolicyDetailsChange}
                value={manualPolicyDetails.System_Selected}
                style={{ ...selectStyleObj }}
              >
                {systemIdList?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </Grid>
            <Grid item xs={6} md={6} paddingLeft={1}>
              <p style={{ fontSize: 13, color: "#17191B" }}>ROE</p>
              <Input
                style={{ ...inputStyleObj }}
                name="Roe"
                onChange={handlePolicyDetailsChange}
                value={manualPolicyDetails.Roe}
                placeholder="Write details here"
                required
              />
            </Grid>
            <Grid item xs={6} md={6} paddingRight={1}>
              <p style={{ fontSize: 13, color: "#17191B" }}>Cash Reference</p>
              <Input
                style={{ ...inputStyleObj }}
                name="Cash_Reference"
                onChange={handlePolicyDetailsChange}
                value={manualPolicyDetails.Cash_Reference}
                placeholder="Write details here"
                required
              />
            </Grid>
            <Grid item md={12} sx={Container} mt={2} mb={3}>
              <CommonButton
                text="Cancel"
                handleClick={onCancel}
                sx={{ backgroundColor: "#ffff", color: "#000000" }}
                variant="outlined"
              />
              <CommonButton
                text="Save"
                handleClick={() => setConfirmDialogBox(true)}
                sx={{ borderRadius: 100, marginLeft: "20px" }}
                hoverColor="#FF5A01"
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <div id="confirm-savedialog-box">
        <Dialog
          open={confirmDialogBox}
          onClose={() => setConfirmDialogBox(false)}
          aria-describedby="confirm-dialog-description"
        >
          <DialogTitle>{"Confirm"}</DialogTitle>
          <DialogContent style={{ minWidth: 300, }}>
            <DialogContentText id="alert-dialog-slide-description">
              Do you want to continue? These changes are not reversible.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CommonButton
              text="Cancel"
              handleClick={() => setConfirmDialogBox(false)}
              sx={{ backgroundColor: "#ffff", color: "#000000" }}
              variant="outlined"
            />
            <CommonButton
              text="Confirm"
              handleClick={handleSave}
              disabled={isLoading}
              sx={{ borderRadius: 100, marginLeft: "20px" }}
              hoverColor="#FF5A01"
            />
          </DialogActions>
        </Dialog>
      </div>

      <div id="confirm-producting-entity-dialog-box">
        <Dialog
          open={productingEntityDialog}
          onClose={() => setProductingEntityDialog(false)}
          aria-describedby="confirm-dialog-description"
        >
          <DialogTitle>{"Confirm"}</DialogTitle>
          <DialogContent style={{ minWidth: 300, }}>
            <DialogContentText id="alert-dialog-slide-description">
              Your old producing entity will be replaced with the new one. Do you want to continue?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CommonButton
              text="Cancel"
              handleClick={() => setProductingEntityDialog(false)}
              sx={{ backgroundColor: "#ffff", color: "#000000" }}
              variant="outlined"
            />
            <CommonButton
              text="Confirm"
              handleClick={() => {
                handleEntityNameDropdownChange(pendingProducingEntity?.name, pendingProducingEntity?.value, pendingProducingEntity?.reason, pendingProducingEntity?.event)
                setProductingEntityDialog(false)
              }}
              sx={{ borderRadius: 100, marginLeft: "20px" }}
              hoverColor="#FF5A01"
            />
          </DialogActions>
        </Dialog>
      </div>

    </div>
  )
}

export default CashAllocationEdit
